@import '../../constants/colors.scss';

.Mui-disabled {
    color: $colorAzul1 !important;
    background-color: $colorAzul6;
    fieldset {
        border-color: $colorAzul1 !important;
    }
}

.alert {
    position: fixed;
    bottom: 46px;
    width: 100%;
}

.buttons-caso-form {
    justify-content: flex-end !important;
    .right-buttons {
        button {
            margin-left: 10px;
        }
    }
}

.document-buttons {
    button {
        margin-right: 10px;
    }
}

.scroll {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}
