@import "../../constants/colors.scss";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 40px;
  background-color: $colorAzul3;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  color: $colorAzul1;
  font-weight: bold;
  .company {
    margin-left: 5vw;
  }
  ul {
    padding: 0;
    margin: 0 5vw 0 0;
    display: flex;
    align-items: center;
    li {
      padding: 0 0 0 10px;
      margin: 0;
      list-style: none;
      &.user {
        .MuiSvgIcon-root {
          margin-right: 10px;
        }
        display: flex;
        align-items: center;
      }
    }
  }
}
