@import "../../constants/colors.scss";
body {
  background-color: $colorAzul6;
}
.layout {
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.delete {
  background-color: $colorRojo1 !important;
  transition: 0.3s all linear;
  &:hover {
    background-color: #b5271c !important;
  }
}

.greenButton {
  background-color: #4bb543 !important;
  transition: 0.3s all linear;
  &:hover {
    background-color: #2f8a28 !important;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1301;
  width: 100%;
  height: 100%;
}
