$colorAzul1: #003468;
$colorAzul2: #6e9cc9;
$colorAzul3: #b5cce4;
$colorAzul4: #c7d8e9;
$colorAzul5: #e7eff8;
$colorAzul6: #f2f5f7;
$colorGris1: #333333;
$colorGris2: #aaaaaa;
$colorGris3: #eaeaea;
$colorGris4: #f0f0f0;
$colorRojo1: #e40e0e;
$colorRojo2: #fce6e6;
$colorBlanco: white;
$colorPurple: #5b2f91;
$colorBlueLigth: #00bce7;
