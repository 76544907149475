@import "../../constants/colors.scss";
.container-login {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100vh;
    margin-top: -50px;
    align-items: center;
    background-size: cover;
}

.login {
    background-color: rgba($color: #ffffff, $alpha: 0.9);
    margin-right: 35vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $colorPurple;
    box-shadow: 0px 50px 50px $colorAzul5;
    .MuiTextField-root {
        margin-bottom: 20px;
    }
}