@import "../../constants/colors.scss";
.casos-table {
  width: 100%;
  .container {
    height: 60vh;
    .MuiTableRow-root {
      &:hover {
        background-color: $colorGris4;
      }
      &:nth-child(odd) {
        background-color: $colorAzul6;
        &:hover {
          background-color: $colorGris4;
        }
      }
    }
  }
  .MuiTableRow-root {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $colorAzul3;
  background-color: $colorAzul6;
  .fill {
    flex: 1;
  }
  .navigation {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    font-size: 12px;

    > span {
      color: $colorAzul1;
      font-size: 12px;
      margin: 0 10px;
    }
  }
  .results {
    font-size: 12px;
    padding-right: 10px;
  }
}
