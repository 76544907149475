@import "../../constants/colors.scss";

.filters {
  margin-bottom: 20px;
  display: column;
  flex-direction: row;

  .fields {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $colorAzul4;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    max-width: 100%;
    align-items: center;
    button {
      padding: 0;
      height: 30px;
    }
  }
  .MuiTextField-root {
    margin: 5px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: 10px;
    }
  }
  .button-row {
    button {
      margin-left: 10px;
    }
  }
}
